export const BASE_URL = '/';
export const HOME_URL = 'https://pactinsurance.com/';
export const WHYPACT_URL = '/whypact';
export const TERMS_URL = '/terms';
export const PRIVACY_URL = '/privacy';
export const BLOG_URL = 'https://blog.pactinsurance.com';
export const ZENDESK_URL = 'https://help.pactinsurance.com/hc/en-us';
export const PRELAUNCH_SUBSCRIBE_URL = 'https://pactinsurance.us16.list-manage.com/subscribe/post-json?u=8eb96ee7611af8dd1d3eac79f&id=b27ea33339';

export const WEB_QUOTING_FLOW = '/quote';
export const WEB_GO_MOBILE = '/goMobile';
export const PAUSE_EXPLAINER = '/pause';

export const FACEBOOK_URL = 'https://www.facebook.com/pactinsurance';
export const TWITTER_URL = 'https://www.twitter.com/pactinsurance';
export const INS_URL = 'https://www.instagram.com/pactinsurance';
export const INSTAGRAM_URL = 'https://instagram.com/pactinsurance';

export const GOOGLE_PLAY_URL = 'https://pact.insure/rb3x4';
export const APP_STORE_URL = 'https://pact.insure/pk9em';
export const MOBILE_APP_URL = 'https://pact.insure/get';

export const HANDOFF_BRANCH_URL = 'https://pactinsurance.app.link/0NtBAwAwyZ';

export const PRESS_URL = 'mailto:press@pactinsurance.com';
