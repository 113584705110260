/**
 * User birthday store class
 */

import { observable } from 'mobx';
import ClientInfoStore from '../../views/clientInfo/ClientInfo.store';
import { validateDate } from '../../utils/dateFormatter';

class Store {
  @observable birthDate = '';
  @observable isDateValid = '';

  handleInputChange = (evt) => {
    this.isDateValid = validateDate(evt.target.value);
    let val = this.limitLength(
      this.extract(evt.target.value, '[0-9/]+'),
      10
    );
    if (val.length === 2) {
      val += '/';

    } else if (val.length === 5) {
      val += '/';
    }
    this.birthDate = val;
  };

  limitLength = (str, length) => str.substring(0, length);
  extract = (str, pattern) => (str.match(pattern) || []).pop() || '';

  keyPressFunc = (evt) => {
    if(evt.which === 8) {
      let val = this.birthDate;
      if(val.length == 3 || val.length == 6) {
        val = val.slice(0, val.length-1);
        this.birthDate = val;
      }
    }
  }

  handleNextStep = () => {
    ClientInfoStore.pushStep(ClientInfoStore.clientAddress);
  };
}

const singleton = new Store();
export default singleton;
