import { observable, action } from 'mobx';
import httpClient from '../dataLayer/httpClientWrapper';

class Store {

  @observable errorMessage = '';
  @observable payPlans = [];
  @observable coverageData = [];
  @observable accidentsData = [];
  @observable violationsData = [];
  @observable occupationEducationData = {};
  @observable lienholderList = {};
  @observable vehicleYMMData = {};

  @action
  getOptions = async (quoteId, token) => {
    try {
      const response = await httpClient({
        method: 'GET',
        url: `/quotes/${ quoteId }/options`,
        token
      });
      this.payPlans = response.data.payPlans;
      this.coverageData = response.data.coverages;
      this.accidentsData = response.data.accidents;
      this.violationsData = response.data.violations;
      this.occupationEducationData = response.data.occupationEducation;
      this.lienholderList = response.data.lienholderList;
      this.vehicleYMMData = response.data.vehicleYMMData;
      return response;
    } catch (error) {
      this.errorMessage = error;
    }
  };

}

const singleton = new Store();
export default singleton;
