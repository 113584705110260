import { observable } from 'mobx';
import { createBrowserHistory } from 'history';

class Store {
  @observable location = null;
  history = createBrowserHistory();

  push(location) {
    this.history.push(location);
  }
  replace(location) {
    this.history.replace(location);
  }
  go(nextPath) {
    this.history.go(nextPath);
  }
  goBack() {
    this.history.goBack();
  }
  goForward() {
    this.history.goForward();
  }
}

const singleton = new Store();
export default singleton;
