/**
 * User address store class
 */

/* eslint-disable no-undef */
import { observable, action, runInAction } from 'mobx';
import ClientInfoStore from '../../views/clientInfo/ClientInfo.store';
import pryDriverInfo from '../../stores/apiStores/primaryDriverInfo.api.store';
// import { ALLOW_STATES_MAP } from '../../constants';

class Store {
  @observable address = '';
  @observable selectValue = '';
  @observable selectedAddressPlace = {};
  @observable createQuoteResponseId = '';
  @observable timezoneId = '';
  @observable address2 = '';
  @observable optionData = {};
  @observable openPopup = false;
  @observable modalAddressActions = [
    { name: 'Understood, let\'s go back', handleClick: this.handleAddOKClick }
  ];

  autocompleteService = null

  @observable addressDetailsForm = [
    'sublocality_level_1',
    'administrative_area_level_1',
    'postal_code'
  ];

  multiFilter = (arr, filterKeys) => {
    return arr.filter((obj) => {
      return Object.values(obj.types).includes(filterKeys);
    })[ 0 ];
  };

  handleInputChange = (evt) => {
    this.selectValue = evt.target.value;

    if (this.autocompleteService) return;

    const input = document.querySelector('[data-type=autocomplete]');
    const autocompleteService = this.autocompleteService = new google.maps.places.Autocomplete(input, {
      types: [ 'geocode' ],
      componentRestrictions: { country: 'US' }
    });
    autocompleteService.addListener('place_changed', () => {
      this.autocompleteService = null;
      const selectedPlace = autocompleteService.getPlace();
      if (!selectedPlace.geometry) {
        return;
      }
      this.selectedAddressPlace.address = selectedPlace.formatted_address;
      this.selectedAddressPlace.address1 = selectedPlace.name;
      this.selectedAddressPlace.stateLongName = this.multiFilter(
        selectedPlace.address_components,
        'administrative_area_level_1'
      )
        ? this.multiFilter(
          selectedPlace.address_components,
          'administrative_area_level_1'
        ).long_name
        : '';
      this.selectedAddressPlace.zipCode = this.multiFilter(
        selectedPlace.address_components,
        'postal_code'
      )
        ? this.multiFilter(selectedPlace.address_components, 'postal_code')
          .long_name
        : '';
      this.selectedAddressPlace.state = this.multiFilter(
        selectedPlace.address_components,
        'administrative_area_level_1'
      )
        ? this.multiFilter(
          selectedPlace.address_components,
          'administrative_area_level_1'
        ).short_name
        : '';
      this.selectedAddressPlace.city = this.getCity(
        selectedPlace.address_components
      );
      this.selectedAddressPlace.country = this.multiFilter(
        selectedPlace.address_components,
        'country'
      )
        ? this.multiFilter(selectedPlace.address_components, 'country')
          .short_name
        : '';
      this.handleResultSelect();
      return selectedPlace;
    });
  };

  getCity = (addressComponents) => {
    return (this.multiFilter(addressComponents, 'locality')
    ? this.multiFilter(addressComponents, 'locality')
      .long_name
    : this.multiFilter(addressComponents, 'neighborhood')
    ? this.multiFilter(addressComponents, 'neighborhood')
      .long_name
    : '');
  }
  handleAddressChange = () => {
    ClientInfoStore.popLevelBack();
  };

  openAddressModal = () => {
    this.openPopup = !this.openPopup;
  }

  @action
  handleAddOKClick = () => {
    this.openAddressModal();
    this.resetAddress();
  }

  @action
  handleResultSelect = () => {
    ClientInfoStore.pushStep(ClientInfoStore.clientAddressDetails);
    // if (this.selectedAddressPlace.city.length === 0 ||
    //   this.selectedAddressPlace.zipCode.length === 0
    //   || this.selectedAddressPlace.state.length === 0 ||
    //   this.selectedAddressPlace.address1.length === 0) {
    //   this.openPopup = true;
    // } else {
    //   ClientInfoStore.pushStep(ClientInfoStore.clientAddressDetails);
    // }
  };

  @action
  handleOptChange = (evt, { value }) => {
    this.address2 = value;
  }

  resetAddress = () => {
    this.selectValue = '';
  }

  @action
  handleNextStep = async (token, optionStore) => {
    const isAvailableState = await this.handleGetQuoteNumber(token, optionStore);

    if (isAvailableState) {
      this.getPrefillOptions(optionStore, this.createQuoteResponseId, token);
      ClientInfoStore.pushStep(ClientInfoStore.clientEmail);
    } else {
      ClientInfoStore.pushStep(ClientInfoStore.pactNotInState);
    }
  }

  @action
  handleGetQuoteNumber = async (token) => {
    const quotesRequestPayLoad = {};
    let isAvailableState = false;

    quotesRequestPayLoad.contractState = this.selectedAddressPlace.state;
    quotesRequestPayLoad.productType = 'owner';
    quotesRequestPayLoad.garageAddress = {
      address1: this.selectedAddressPlace.address1,
      address2: this.address2,
      city: this.selectedAddressPlace.city,
      state: this.selectedAddressPlace.state,
      zip: this.selectedAddressPlace.zipCode
    };
    try {
      const quotesIdentity = await pryDriverInfo.createQuotes(
        quotesRequestPayLoad, token
      );
      if (quotesIdentity.statusText === 'OK') {
        isAvailableState = true;
        this.createQuoteResponseId = quotesIdentity.data.id;
        this.timezoneId = quotesIdentity.data.timezoneId;
      }
    } catch (error) {
      runInAction(() => {
        this.errorMesssage = error;
      });
    }
    return isAvailableState;
  };

  @action
  getPrefillOptions = async (optionStore, createQuoteResponseId, token) => {
    try {
      // if (createQuoteResponseId.length > 0 && Object.entries(this.optionData).length === 0 && this.optionData.constructor === Object){
      //   this.optionData = await optionStore.getOptions(createQuoteResponseId, token);
      // } else {
      //   return;
      // }
      if (this.selectedAddressPlace.prevStateLongName !== this.selectedAddressPlace.stateLongName) {
        this.optionData = await optionStore.getOptions(createQuoteResponseId, token);
        this.selectedAddressPlace.prevStateLongName = this.selectedAddressPlace.stateLongName;
      }
    } catch (error) {
      this.errorMesssage = error;
    }
  }
}

const singleton = new Store();
export default singleton;
