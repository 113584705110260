import { observable, action } from 'mobx';
import httpClient from '../dataLayer/httpClientWrapper';
import BaseStore from './Base.store';
import AuthStore from './Auth.store';
import OptionsStore from './Options.store';
import DriverPrefillStore from './apiStores/DriverPrefill.store';
// import GoogleApiStore from './GoogleApi.store';
// import vehicleDataApiStore from './apiStores/vehicleData.api.store';
import ClientAddressStore from '../components/clientAddress/ClientAddress.store';
import ClientNameStore from '../components/clientName/ClientName.store';
import ClientEmailStore from '../components/clientEmail/clientEmail.store';
import ClientBirthdateStore from '../components/clientBirthdate/ClientBirthdate.store';
import ClientInfoStore from '../views/clientInfo/ClientInfo.store';
import { getParameterByName, jwtDecode, toUpperCaseFirst } from '../utils/utils';

const sample = [
  {
    'address': '200 W Nationwide Blvd',
    'address_2': '',
    'bi_per_incident': 100000,
    'bi_per_person': 50000,
    'city': 'Columbus',
    'contact': 'Frank Alvarez',
    'continuous_coverage': 12,
    'coverage_type': 'Superior Protection',
    'current_company': 'infinity',
    'current_customer': 12,
    'current_policy_expires': 30,
    'currently_insured': 1,
    'drivers': [
      {
        'bankruptcy': 0,
        'birth_date': '1961-08-20',
        'credit_rating': 'Good',
        'driver': 'Frank Alvarez',
        'education': 'High School',
        'first_licensed': '17',
        'gender': 'M',
        'good_student': 0,
        'license_state': 'OH',
        'license_status': 'Active',
        'marital_status': 'married',
        'occupation': 'Professional Salaried',
        'primary_vehicle': 0,
        'relationship': 'self',
        'sr_22': 0
      }
    ],
    'email': 'alvarezfrank666@gmail.com',
    'home_length': 24,
    'home_ownership': 1,
    'incidents': [
      {
        'description': 'More than 10 MPH over',
        'driver': 0,
        'incident_date': '06/2012',
        'type': 'ticket'
      },
      {
        'amount_paid': '200',
        'claim_at_fault': 1,
        'description': 'Act of Nature',
        'driver': 0,
        'incident_date': '03/2012',
        'type': 'claim'
      },
      {
        'accident_at_fault': 1,
        'amount_paid': '450',
        'description': 'Hit while stopped',
        'driver': 0,
        'incident_date': '09/2012',
        'liability_medical_paid': 0,
        'type': 'accident',
        'what_damaged': 'property'
      },
      {
        'amount_paid': '550',
        'claim_at_fault': 0,
        'description': 'Hail damage',
        'driver': 0,
        'incident_date': '06/2010',
        'type': 'claim'
      }
    ],
    'leadid_id': '',
    'military_affiliation': 0,
    'phone': '',
    'state': 'OH',
    'vehicles': [
      {
        'alarm': 0,
        'annual_mileage': 4500,
        'average_mileage': 8,
        'collision': 1000,
        'commute_days_per_week': 5,
        'comprehensive': 1000,
        'make': 'Pontiac',
        'model': 'Solstice',
        'ownership': 'finance',
        'primary_driver': 0,
        'primary_purpose': 'Commute Work',
        'submodel': 'GXP',
        'vin': '1G2MG35X08Y000000',
        'year': 2008
      },
      {
        'alarm': 0,
        'annual_mileage': 9500,
        'average_mileage': 17,
        'collision': 1000,
        'commute_days_per_week': 5,
        'comprehensive': 1000,
        'make': 'Ford',
        'model': 'F-150',
        'ownership': 'own',
        'primary_driver': 0,
        'primary_purpose': 'Commute Work',
        'submodel': 'XLT',
        'vin': '2FTRF17W0XC000000',
        'year': 1999
      }
    ],
    'zebra_sample_id': 0,
    'zip': '43215'
  }
];

class ZebraStore extends BaseStore {
  @observable jwt = {};
  @observable loading = false;
  @observable isZebra = false;
  @observable primaryDriverVehicles = [];
  @observable monthsOfContinuousInsurance = '';

  @action
  initPrefillData = async () => {
    this.loading = true;
    this.isZebra = true;
    try {
      // await this.getExternalLeadsZebra();
      await this.getSession();
      await this.createZebraQuote();
      await this.getOptions();
      await this.getDriverZebraPrefill();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  @action
  getExternalLeadsZebra = async () => {
    const { data: { pactUrl } } = await httpClient({
      method: 'POST',
      url: '/externalLeads/zebra',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        'external-lead-auth-key': 'dc359ca8-1726-40c8-87f9-b9405d1a991c'
      },
      data: sample[ 0 ]
    });

    const jwt = getParameterByName('jwt', pactUrl);
    this.jwt = jwtDecode(jwt);
  };

  @action
  getSession = async () => {
    await ClientNameStore.handleGetSessionToken(AuthStore);
  };

  @action
  createZebraQuote = async () => {
    const { data } = await httpClient({
      method: 'POST',
      url: '/quotes',
      data: {
        externalLeadId: this.jwt.externalLeadId,
        source: 'zebra'
      },
      token: AuthStore.apiToken
    });

    if (data === 'Pact is not yet available in your state.') {
      window.history.replaceState(null, '', '/joinPact');
      window.location.reload();
      throw new Error('Pact is not yet available in your state.');
    }

    ClientAddressStore.createQuoteResponseId = data.id;
    ClientAddressStore.timezoneId = data.timezoneId;

    const myselfData = (data.prefillQuoteData.prefillDrivers || []).find((item) => {
      return item.driver.relationToApplicant === 'applicant';
    });

    if (myselfData.driver) {
      ClientBirthdateStore.birthDate = myselfData.driver.person.birthDate;
      ClientNameStore.firstName = myselfData.driver.person.name.firstName;
      ClientNameStore.lastName = myselfData.driver.person.name.lastName;
    }

    this.primaryDriverVehicles = (data.prefillQuoteData.prefillVehicles || []).map((item) => {
      const vehicle = item.vehicle;
      const { vin, year, ...vehicleIdentification } = vehicle.identification;

      // const { data } = await vehicleDataApiStore.getVehicleDataFromYMM(
      //   {
      //     ...vehicleIdentification,
      //     year: String(year)
      //   },
      //   AuthStore.apiToken
      // );

      return {
        ...vehicle,
        identification: {
          ...vehicleIdentification,
          vin: vin || '',
          year: String(year)
        },
        ownershipStatus: toUpperCaseFirst(vehicle.ownershipStatus),
        imageUrl: ''
      };
    });

    DriverPrefillStore.drivers = (data.prefillQuoteData.prefillDrivers || []).map((item) => {
      const driver = item.driver;

      return {
        ...driver
      };
    });

    try {
      this.monthsOfContinuousInsurance = data.prefillQuoteData.prefillNamedInsured.namedInsured.priorInsurance.monthsOfContinuousInsurance;
    } catch (error) {
      this.monthsOfContinuousInsurance = '';
    }
  };

  @action
  getOptions = async () => {
    ClientAddressStore.optionData = await OptionsStore.getOptions(ClientAddressStore.createQuoteResponseId, AuthStore.apiToken);
  }

  @action
  getDriverZebraPrefill = async () => {
    const response = await DriverPrefillStore.driverPrefill(
      {},
      ClientAddressStore.createQuoteResponseId,
      AuthStore.apiToken,
      true
    );
    ClientEmailStore.driverPrefillData = response;
  }

  nextStep = () => {
    ClientInfoStore.pushStep(ClientInfoStore.vehicleBasicInfo);
  }
}

const singleton = new ZebraStore();
export default singleton;
