import { observable, action } from 'mobx';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import Branch from 'branch-sdk';
import ReactPixel from 'react-facebook-pixel';
import httpClient from '../dataLayer/httpClientWrapper';

class Store {

  @observable apiToken = null;
  @observable errorMessage = '';
  @observable loading = true;

  @action
  getSession = async () => {
    try {
      const response = await httpClient({
        method: 'POST',
        url: '/sessions',
        data: {
          type: 'webQuoting'
        }
      });
      if(Object.entries(response.data).length === 0 &&
      response.data.constructor === Object) {
        this.loading = true;
      } else {
        ReactPixel.track('Generated session token');
        Branch.track('Generated session token');
        mixpanel.track('Generated session token request success');
        TagManager.dataLayer({
          dataLayer: {
            event: 'quoteFlow',
            name: 'Generate session',
            step: 111
          }
        });
        this.apiToken = response.data.token;
        this.loading = false;
        return response;
      }
    } catch (error) {
      this.errorMessage = error;
    }
  };

}

const singleton = new Store();
export default singleton;
