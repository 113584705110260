import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import { Provider } from 'mobx-react';
import { AuthStore, OptionStore, DriverPrefillStore, CoveragePackage,
  AffinityStore, GoogleApiStore } from './stores';
import './index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'normalize.css/normalize.css';
import * as serviceWorker from './serviceWorker';

const App = lazy(() => import('./App'));

const stores = {
  AuthStore,
  OptionStore,
  DriverPrefillStore,
  CoveragePackage,
  AffinityStore,
  GoogleApiStore
};

ReactDOM.render(
  <Provider { ...stores }>
    <Suspense fallback>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
