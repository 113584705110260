import { action, observable } from 'mobx';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import Branch from 'branch-sdk';
import ReactPixel from 'react-facebook-pixel';
import httpClient from '../../dataLayer/httpClientWrapper';
import primaryDriverInfoApiStore from './primaryDriverInfo.api.store';
import ClientAddressStore from '../../components/clientAddress/ClientAddress.store';

class Store {
  @observable loading = true;
  @observable errorMessage = '';
  @observable isLoadingSetVehicle = true

  @action
  resetLoading = () => {
    this.loading = true;
  }
  @action
  getVehicleDataFromVIN = async(vin, token) => {
    this.resetLoading();
    try {
      const vehicleDataResponse = await httpClient({
        method: 'GET',
        url: `/quotes/${ primaryDriverInfoApiStore.quoteId }/vinDecode/${ vin }`,
        token
      });
      if  (
        Object.entries(vehicleDataResponse.data).length === 0 &&
        vehicleDataResponse.data.constructor === Object
      ) {
        this.loading = true;
      } else {
        this.loading = false;
        return vehicleDataResponse;
      }
    } catch(err){
      this.errorMessage = err;
    }
  };

  @action
  setVehicle = async(data, quoteId, token) => {
    try {
      ReactPixel.track('Set vehicles request sent');
      Branch.track('Set vehicles request sent');
      const setVehicleResponse = await httpClient({
        method: 'POST',
        url: `/quotes/${ quoteId }/setVehicles`,
        data,
        token
      });
      if (
        Object.entries(setVehicleResponse.data).length === 0 &&
        setVehicleResponse.data.constructor === Object
      ) {
        this.isLoadingSetVehicle = true;
      } else {
        this.isLoadingSetVehicle = false;
      }
      mixpanel.track('Set vehicles request success', {
        'Quote ID': quoteId,
        'Quote State': ClientAddressStore.selectedAddressPlace.state
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'quoteFlow',
          name: 'Set vehicles',
          step: 141
        }
      });
      return setVehicleResponse;
    } catch (err) {
      this.errorMessage = err;
    }
  };

  getVehicleDataFromYMM = async(data, token) => {
    this.resetLoading();
    try {

      const vehicleDataResponseYMM = await httpClient({
        method: 'POST',
        url: `/quotes/${ primaryDriverInfoApiStore.quoteId }/ymmDecode`,
        data,
        token
      });
      if  (
        Object.entries(vehicleDataResponseYMM.data).length === 0 &&
        vehicleDataResponseYMM.data.constructor === Object
      ) {
        this.loading = true;
      } else {
        this.loading = false;
        return vehicleDataResponseYMM;
      }
    } catch(err) {
      this.errorMessage = err;
    }
  }
}

const singleton = new Store();
export default singleton;
