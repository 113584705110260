import moment from "moment-timezone";
import jwt_decode from "jwt-decode";
import { TIMEZONE_ID } from '../constants';


export const isMobile = (userAgent = navigator.userAgent) => {
  return userAgent.match(/ipad|iphone|ipod|android/i)
}

export const multiFilter = (arr: [], filterKeys: string) => {
  return arr.filter((obj: any) => {
    return Object.values(obj.types).includes(filterKeys);
  })[ 0 ];
};

export const limitLength = (str: string, length: number) => str.substring(0, length);

export const setDefaultTimezone = (id = TIMEZONE_ID) => {
  return moment.tz.setDefault(id)
}

export const getTimezoneAbbr = (id = TIMEZONE_ID) => {
  return moment.tz(id).zoneAbbr();
}

export const formatDateInTimezone = (date = new Date(), id = TIMEZONE_ID) => {
  return moment.tz(date, id);
}

export function getParameterByName(pname: string, purl?: string) {
	let name = pname;
	let url = purl;

	if (!url) url = window.location.href;

	name = name.replace(/[[\]]/g, '\\$&');

	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);

	if (!results) return null;
	if (!results[2]) return '';

	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function jwtDecode(token: string) {
  return jwt_decode(token)
}

export function toUpperCaseFirst(str: string) {
  return str.replace(str[ 0 ], str[ 0 ].toUpperCase())
}

export function loadScript(src: string) {
  const script = document.createElement('script')
  script.src = src
  script.type = 'text/javascript'
  document.body.appendChild(script)
}

export function compareVINequal(v1 = '', v2 = '') {
  if (!v1.length || !v2.length) return

  const v1Part1 = v1.slice(0,8)
  const v1Part2 = v1.slice(9,11)

  const v2Part1 = v2.slice(0,8)
  const v2Part2 = v2.slice(9,11)

  return v1Part1 === v2Part1 && v1Part2 === v2Part2
}

export function autoFocusInput(selector: string, timeout = 1700) {
  const waitForExternal = setInterval(() => {
    const inputDOM: any = document.querySelector(selector);
    if (inputDOM) {
      clearInterval(waitForExternal);

      setTimeout(() => {
        if (inputDOM.localName === 'div') {
          inputDOM.children[0].focus();
          return;
        }
        inputDOM.focus();
      }, timeout);
    }
  }, 100)
}

export function leapYear(year: any) {
  return !(year % (year % 100 ? 4 : 400));
}
