/**
 * Pact web picture network path configuration file
 */

export default {
  pauseibilities: {
    'waiting_passenger': '/images/web/pause/waiting_passenger.svg',
  },
  L3: {
    'Work_From_Home_Circle': '/images/web/pause/L3/Work From Home Circle.jpg',
    'Work_From_Home_Banner': '/images/web/pause/L3/Work From Home Banner.png',
    'Vacationer_Banner': '/images/web/pause/L3/Vacationer Banner.jpg',
    'Vacationer_Circle': '/images/web/pause/L3/Vacationer Circle.jpg',
    'Truck_Banner': '/images/web/pause/L3/Truck Banner.jpg',
    'Truck_Circle': '/images/web/pause/L3/Truck Circle.jpg',
    'SalesPerson_Banner': '/images/web/pause/L3/SalesPerson Banner.jpg',
    'Salesperson_Circle': '/images/web/pause/L3/Salesperson Circle.jpg',
    'Nurse_Circle': '/images/web/pause/L3/Nurse Circle.jpg',
    'Pilot_Banner': '/images/web/pause/L3/Pilot Banner.jpg',
    'Military_Banner': '/images/web/pause/L3/Military Banner.jpg',
    'Military_Circle': '/images/web/pause/L3/Military Circle.jpg',
    'Nurse_Banner': '/images/web/pause/L3/Nurse Banner.jpg',
    'Lawyer_Circle': '/images/web/pause/L3/Lawyer Circle.jpg',
    'MicroMo_Banner': '/images/web/pause/L3/MicroMo Banner.jpg',
    'Freelancer_Circle': '/images/web/pause/L3/Freelancer Circle.jpg',
    'Lawyer_Banner': '/images/web/pause/L3/Lawyer Banner.jpg',
    'Flight_Attendent_Circle': '/images/web/pause/L3/Flight Attendent Circle.jpg',
    'Freelancer_Banner': '/images/web/pause/L3/Freelancer Banner.jpg',
    'Doctor_Banner': '/images/web/pause/L3/Doctor Banner.jpg',
    'Flight_Attendent_Banner': '/images/web/pause/L3/Flight Attendent Banner.jpg',
    'Consultant_Banner': '/images/web/pause/L3/Consultant Banner.jpg',
    'Action_Banner': '/images/web/pause/L3/Action Banner.jpg',
  },
  L3_icons: {
    'Group_cover': '/images/web/pause/Group_cover.png',
    'icon_truck': '/images/web/pause/Icon/icon_truck_512px_cropped.png',
    'icon_scooter': '/images/web/pause/Icon/icon_scooter_512px_cropped.png',
    'icon_presentation': '/images/web/pause/Icon/icon_presentation_512px_cropped.png',
    'icon_scale': '/images/web/pause/Icon/icon_scale_512px_cropped.png',
    'icon_laptop': '/images/web/pause/Icon/icon_laptop_512px_cropped.png',
    'icon_medical': '/images/web/pause/Icon/icon_medical_512px_cropped.png',
    'icon_military_badge': '/images/web/pause/Icon/icon_military_badge_512px_cropped.png',
    'icon_passport': '/images/web/pause/Icon/icon_passport_512px_cropped.png',
    'icon_first_aid_kit': '/images/web/pause/Icon/icon_first_aid_kit_512px_cropped.png',
    'icon_island': '/images/web/pause/Icon/icon_island_512px_cropped.png',
    'icon_backpack': '/images/web/pause/Icon/icon_backpack_512px_cropped.png',
    'icon_briefcase': '/images/web/pause/Icon/icon_briefcase_512px_cropped.png',
    'icon_work_from_home2': '/images/web/pause/Icon/icon_work_from_home_512px.png',
    'icon_airplane1': '/images/web/pause/Icon/icon_airplane1_512px_cropped.png',
    'icon_airplane2': '/images/web/pause/Icon/icon_airplane2_512px_cropped.png',
    'icon_airport': '/images/web/pause/Icon/icon_airport_512px_cropped.png',
    'icon_work_from_home': '/images/web/pause/Icon/icon_work_from_home_512px_cropped.png',
  },

  home: {
    'avatar1': '/images/web/home/avatar1.png',
    'avatar2': '/images/web/home/avatar2.png',
    'avatar3': '/images/web/home/avatar3.png',
    'banner': '/images/web/home/banner.png',
    'hand_on_phone': '/images/web/home/hand_on_phone.png',
    'hompage_section2_icon1': '/images/web/home/hompage_section2_icon1.png',
    'hompage_section2_icon2': '/images/web/home/hompage_section2_icon2.png',
    'hompage_section2_icon3': '/images/web/home/hompage_section2_icon3.png',
    'Icon_Get_a_quote': '/images/web/home/Icon - Get a quote in 90 secs@3x.png',
    'Icon_Manage_completely': '/images/web/home/Icon - Manage completely in-app@3x.png',
    'Icon_Save_an_additional': '/images/web/home/Icon - Save an additional 40p@3x.png',
    'Screen_2_Phone_Images': '/images/web/home/Screen_2_Phone_Images@3x.png',
    'You_Could_Wait_Image_1': '/images/web/home/You_Could_Wait_Image_1.png',
    'You_Could_Wait_Image_2': '/images/web/home/You_Could_Wait_Image_2.png',
    'You_Could_Wait_Image_3': '/images/web/home/You_Could_Wait_Image_3.png',
    'You_Decide_How_Much': '/images/web/home/You_Decide_How_Much@3x.png',
  },
  busniess: {
    'gradient_background': '/images/web/busniess/gradient_background@3x.png',
    'icon_1_claims_management': '/images/web/busniess/icon_1_claims_management.svg',
    'icon_2_core_competency': '/images/web/busniess/icon_2_core_competency.svg',
    'icon_3_user_experience': '/images/web/busniess/icon_3_user_experience.svg',
    'icon_4_business_growth': '/images/web/busniess/icon_4_business_growth.svg',
    'icon_5_solution_that_scales': '/images/web/busniess/icon_5_solution_that_scales.svg',
    'image_1_make_insurance_simple_high_res': '/images/web/busniess/image_1_make_insurance_simple_high_res@0.75x.png',
    'image_2_connected_mobility': '/images/web/busniess/image_2_connected_mobility@3x.png',
    'image_3_iphone_screenshot_scooter': '/images/web/busniess/image_3_iphone_screenshot_scooter.png',
    'image_4_simple_api_integration': '/images/web/busniess/image_4_simple_api_integration@3x.png',
    'Profile_Photo_1': '/images/web/busniess/Profile_Photo_1@3x.png',
    'Profile_Photo_2': '/images/web/busniess/Profile_Photo_2@3x.png',
    'Profile_Photo_3': '/images/web/busniess/Profile_Photo_3@3x.png',
  },
  whypact: {
    'car': '/images/web/whypact/car.png',
    'car1': '/images/web/whypact/car1.png',
    'car2': '/images/web/whypact/car2.png',
    'car3': '/images/web/whypact/car3.png',
    'greenLine': '/images/web/whypact/greenLine.png',
    'greenLine2': '/images/web/whypact/greenLine2.png',
    'icon1': '/images/web/whypact/icon1.png',
    'icon2': '/images/web/whypact/icon2.png',
    'icon3': '/images/web/whypact/icon3.png',
    'icon4': '/images/web/whypact/icon4.png',
    'icon5': '/images/web/whypact/icon5.png',
    'icon6': '/images/web/whypact/icon6.png',
    'icon7': '/images/web/whypact/icon7.png',
    'icon8': '/images/web/whypact/icon8.png',
    'icon9': '/images/web/whypact/icon9.png',
    'icon10': '/images/web/whypact/icon10.png',
    'leftImg': '/images/web/whypact/leftImg.png',
    'leftImg2': '/images/web/whypact/leftImg2.png',
    'man_dog': '/images/web/whypact/man_dog.jpg',
    'part_e_img': '/images/web/whypact/part_e_img.png',
    'woman': '/images/web/whypact/woman.jpg',
  },
  quote: {
    'User_Photo': '/images/web/quote/User Photo@3x.png',
    'Union': '/images/web/quote/Union.png',
    'progress_indicator_incomplete': '/images/web/quote/progress_indicator_incomplete.svg',
    'progress_indicator_complete': '/images/web/quote/progress_indicator_complete.svg',
    'progress_indicator_current': '/images/web/quote/progress_indicator_current.svg',
    'Logo_iOS_App_Store': '/images/web/quote/Logo_iOS_App_Store.svg',
    'input_checkbox': '/images/web/quote/input_checkbox.png',
    'Logo_Android_Google_Play': '/images/web/quote/Logo_Android_Google_Play.svg',
    'icon_warning_message': '/images/web/quote/icon_warning_message.svg',
    'icon_share_twitter': '/images/web/quote/icon_share_twitter.svg',
    'icon_ssl_shield': '/images/web/quote/icon_ssl_shield.svg',
    'icon_share_facebook': '/images/web/quote/icon_share_facebook.svg',
    'icon_share_instagram': '/images/web/quote/icon_share_instagram.svg',
    'icon_select_off': '/images/web/quote/icon_select_off.svg',
    'icon_select_on': '/images/web/quote/icon_select_on.svg',
    'icon_plus_circle': '/images/web/quote/icon_plus_circle.svg',
    'icon_menu_black': '/images/web/quote/icon_menu_black.svg',
    'icon_close_white': '/images/web/quote/icon_close_white.svg',
    'icon_dropdown_arrow': '/images/web/quote/icon_dropdown_arrow.svg',
    'icon_close_grey': '/images/web/quote/icon_close_grey.svg',
    'icon_circle_checked': '/images/web/quote/icon_circle_checked.svg',
    'icon_circle_unchecked': '/images/web/quote/icon_circle_unchecked.svg',
    'icon_arrow_left': '/images/web/quote/icon_arrow_left.svg',
  },
  pause: {
    Headshots: {
      'Zoe1': '/images/web/pause/Headshots/Zoe1.png',
      'Julia': '/images/web/pause/Headshots/Julia.jpg',
      'User_1@3x': '/images/web/pause/Headshots/User_1@3x.png',
      'User_2@3x': '/images/web/pause/Headshots/User_2@3x.png',
      'User_3@3x': '/images/web/pause/Headshots/User_3@3x.png',
      'Charles': '/images/web/pause/Headshots/Charles.jpg',
      'Jake': '/images/web/pause/Headshots/Jake.jpg',
      'Jermaine': '/images/web/pause/Headshots/Jermaine.jpg',
    },
    Icon: {
      'icon_truck': '/images/web/pause/Icon/icon_truck_512px_cropped.png',
      'icon_scooter': '/images/web/pause/Icon/icon_scooter_512px_cropped.png',
      'icon_presentation': '/images/web/pause/Icon/icon_presentation_512px_cropped.png',
      'icon_scale': '/images/web/pause/Icon/icon_scale_512px_cropped.png',
      'icon_laptop': '/images/web/pause/Icon/icon_laptop_512px_cropped.png',
      'icon_medical': '/images/web/pause/Icon/icon_medical_512px_cropped.png',
      'icon_military_badge': '/images/web/pause/Icon/icon_military_badge_512px_cropped.png',
      'icon_passport': '/images/web/pause/Icon/icon_passport_512px_cropped.png',
      'icon_first_aid_kit': '/images/web/pause/Icon/icon_first_aid_kit_512px_cropped.png',
      'icon_island': '/images/web/pause/Icon/icon_island_512px_cropped.png',
      'icon_backpack': '/images/web/pause/Icon/icon_backpack_512px_cropped.png',
      'icon_briefcase': '/images/web/pause/Icon/icon_briefcase_512px_cropped.png',
      'icon_work_from_home2': '/images/web/pause/Icon/icon_work_from_home_512px.png',
      'icon_airplane1': '/images/web/pause/Icon/icon_airplane1_512px_cropped.png',
      'icon_airplane2': '/images/web/pause/Icon/icon_airplane2_512px_cropped.png',
      'icon_airport': '/images/web/pause/Icon/icon_airport_512px_cropped.png',
      'icon_work_from_home': '/images/web/pause/Icon/icon_work_from_home_512px_cropped.png',
    },
    L3: {
      'Work_From_Home_Circle': '/images/web/pause/L3/Work From Home Circle.jpg',
      'Work_From_Home_Banner': '/images/web/pause/L3/Work From Home Banner.png',
      'Vacationer_Banner': '/images/web/pause/L3/Vacationer Banner.jpg',
      'Vacationer_Circle': '/images/web/pause/L3/Vacationer Circle.jpg',
      'Truck_Banner': '/images/web/pause/L3/Truck Banner.jpg',
      'Truck_Circle': '/images/web/pause/L3/Truck Circle.jpg',
      'SalesPerson_Banner': '/images/web/pause/L3/SalesPerson Banner.jpg',
      'Salesperson_Circle': '/images/web/pause/L3/Salesperson Circle.jpg',
      'Nurse_Circle': '/images/web/pause/L3/Nurse Circle.jpg',
      'Pilot_Banner': '/images/web/pause/L3/Pilot Banner.jpg',
      'Military_Banner': '/images/web/pause/L3/Military Banner.jpg',
      'Military_Circle': '/images/web/pause/L3/Military Circle.jpg',
      'Nurse_Banner': '/images/web/pause/L3/Nurse Banner.jpg',
      'Lawyer_Circle': '/images/web/pause/L3/Lawyer Circle.jpg',
      'MicroMo_Banner': '/images/web/pause/L3/MicroMo Banner.jpg',
      'Freelancer_Circle': '/images/web/pause/L3/Freelancer Circle.jpg',
      'Lawyer_Banner': '/images/web/pause/L3/Lawyer Banner.jpg',
      'Flight_Attendent_Circle': '/images/web/pause/L3/Flight Attendent Circle.jpg',
      'Freelancer_Banner': '/images/web/pause/L3/Freelancer Banner.jpg',
      'Doctor_Banner': '/images/web/pause/L3/Doctor Banner.jpg',
      'Flight_Attendent_Banner': '/images/web/pause/L3/Flight Attendent Banner.jpg',
      'Consultant_Banner': '/images/web/pause/L3/Consultant Banner.jpg',
      'Action_Banner': '/images/web/pause/L3/Action Banner.jpg',
    },
    'waiting_passenger': '/images/web/pause/waiting_passenger.svg',
    'Vector': '/images/web/pause/Vector.svg',
    'Tent_Camping': '/images/web/pause/Tent_Camping.svg',
    'pause_drop': '/images/web/pause/pause_drop.svg',
    'pause_walkingman': '/images/web/pause/pause_walkingman.svg',
    'pause_car': '/images/web/pause/pause_car.svg',
    'pact_mobile_preview': '/images/web/pause/pact_mobile_preview.svg',
    'Limitless_Pauseibilities_Work': '/images/web/pause/Limitless Pauseibilities - Work - Large.jpg',
    'Limitless_Pauseibilities_Save': '/images/web/pause/Limitless Pauseibilities - Save - Large.jpg',
    'Limitless_Pauseibilities_Play': '/images/web/pause/Limitless Pauseibilities - Play - Large.jpg',
    'Limitless_Pauseibilities_Move': '/images/web/pause/Limitless Pauseibilities - Move - Large.jpg',
    'Limitless_Pauseibilities_Explore': '/images/web/pause/Limitless Pauseibilities - Explore - Large.jpg',
    'Limitless_Pauseibilities_Adventure': '/images/web/pause/Limitless Pauseibilities - Adventure - Large.jpg',
    'group_image': '/images/web/pause/group_image.svg',
    'Airbus_A320': '/images/web/pause/Airbus_A320.svg',
    'Bicycle': '/images/web/pause/Bicycle.svg',
    'black_car': '/images/web/pause/black_car.svg',
    'boarding_passenger': '/images/web/pause/boarding_passenger.svg',
    'dollar_drop': '/images/web/pause/dollar_drop.svg',
    'Electric_scooter': '/images/web/pause/Electric_scooter.svg',
    'Group_cover': '/images/web/pause/Group_cover.png',
  },
  common: {
    header: {
      'icon_menu': '/images/web/common/header/icon_menu.svg',
      'icon_menu_black': '/images/web/common/header/icon_menu_black.svg'
    },
    footer: {
      'twitter': '/images/web/common/footer/twitter.png',
      'play_icon': '/images/web/common/footer/play_icon.png',
      'Icon_AM_Best_Rated_Coverage': '/images/web/common/footer/Icon - AM Best Rated Coverage@3x.png',
      'ins': '/images/web/common/footer/ins.png',
      'google-play-badge': '/images/web/common/footer/google-play-badge.png',
      'facebook': '/images/web/common/footer/facebook.png',
      'excellent': '/images/web/common/footer/excellent.svg',
      'app-store-badge': '/images/web/common/footer/app-store-badge.png',
    }
  }
}
