import { action } from 'mobx';

class BaseStore {
  @action.bound
  update(defaultKey, defaultValue) {
    if (typeof defaultKey === 'string') {
      this[ defaultKey ] = defaultValue;
    } else {
      Object.assign(this, defaultKey);
    }
  }
}

export default BaseStore;
