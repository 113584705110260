import axios from 'axios';
import mixpanel from 'mixpanel-browser';
// import Navigation from '../stores/Navigation.store';
import ClientInfoStore from '../views/clientInfo/ClientInfo.store';
import { INTERNAL_API } from '../constants';

const allowedErrorStatusMap = [ 451 ];

const getHeaders = (webToken, apiToken = '') => {
  const acceptHeader = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  return  apiToken.length > 0 ?
  { ...acceptHeader,
    'Authorization': `Bearer ${ apiToken }`  } :
  { ...acceptHeader,
    'cache-control': 'no-cache',
    'web-quoting-session-token': webToken
  };
};
export default async ({ method, url, data, token, headers }) => {
  try {
    const response = await axios({
      method : method,
      url: `${ INTERNAL_API }${ url }`,
      data: data,
      headers: headers || getHeaders(token)
    });
    if (process.env.REACT_APP_DEBUG) {
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(data, null, 2));
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(response.data, null, 2));
    }
    return response;
  } catch(err) {
    if (process.env.REACT_APP_DEBUG) {
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(err.response, null, 2));
    }
    if (err.response) {
      if (err.response.data && err.response.data.errorId) {
        if (!allowedErrorStatusMap.includes(err.response.status)) {
          ClientInfoStore.isShowErrorPopup = true;
          ClientInfoStore.errorPopupID = (err.response.data && err.response.data.errorId) || '';
          mixpanel.track('Error screen loaded', {
            'Error ID': err.response.data.errorId,
            'Failed Endpoint': url
          });
          // Navigation.history.replace(`/error?errorId=${ (err.response.data && err.response.data.errorId) || '' }`);
        }
      }
      return err.response;
    } else if (err.request) {
      return err.request;
    }
    else {
      return err.message;
    }
  }
};
