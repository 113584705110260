import { action, observable, runInAction } from 'mobx';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import Branch from 'branch-sdk';
import ReactPixel from 'react-facebook-pixel';
import httpClient from '../../dataLayer/httpClientWrapper';
import ClientInfoStore from '../../views/clientInfo/ClientInfo.store';
import clientEmailStore from '../../components/clientEmail/clientEmail.store';
import ClientNameStore from '../../components/clientName/ClientName.store';
import ClientAddressStore from '../../components/clientAddress/ClientAddress.store';
import ZebraStore from '../Zebra.store';
import { compareVINequal } from '../../utils/utils';

class Store {
  @observable errorMessage = '';
  @observable loading = true;
  primaryDriverVehicles = [];
  @observable drivers = [];
  @observable source = '';
  @observable carriers = [];

  @action
  driverPrefill = async (data, quoteId, token, isZebra) => {
    try {
      const prefillResponse = await httpClient({
        method: 'POST',
        url: `/quotes/${ quoteId }/driverPrefill`,
        data,
        token
      });
      runInAction(() => {
        if (
          Object.entries(prefillResponse.data).length === 0 &&
          prefillResponse.data.constructor === Object
        ) {
          this.loading = true;
        } else {
          ReactPixel.track('Driver prefill request success');
          Branch.track('Driver prefill request success');
          mixpanel.people.set({ '$first_name': ClientNameStore.firstName });
          mixpanel.people.set({ '$last_name': ClientNameStore.lastName });
          mixpanel.identify(clientEmailStore.email);
          mixpanel.track('Driver prefill request success', {
            'Quote ID': quoteId,
            'Quote State': ClientAddressStore.selectedAddressPlace.state
          });
          TagManager.dataLayer({
            dataLayer: {
              event: 'quoteFlow',
              name: 'Driver prefill',
              step: 131
            }
          });
          if (!isZebra) {
            this.primaryDriverVehicles = prefillResponse.data.vehicles;
            this.drivers =
              prefillResponse.data.drivers.length > 0
                ? prefillResponse.data.drivers.sort(
                  (secDriver, primaryDriver) => {
                    return primaryDriver.isRequestor - secDriver.isRequestor;
                  }
                )
                : prefillResponse.data.drivers;
            this.source = prefillResponse.data.source;
            this.carriers = prefillResponse.data.carriers;
          }
        }
      });
      if (isZebra) {
        this.primaryDriverVehicles = ZebraStore.primaryDriverVehicles.map((item) => {
          let matchObj = null;

          prefillResponse.data.vehicles.forEach((vehicle) => {
            if (compareVINequal(item.identification.vin, vehicle.identification.vin)) {
              matchObj = {
                vin: vehicle.identification.vin,
                model: vehicle.identification.model,
                bodyStyle: vehicle.identification.bodyStyle
              };
            }
          });

          if (matchObj) {
            return {
              ...item,
              match: true,
              identification: {
                ...item.identification,
                ...matchObj
              }
            };
          } else {
            return {
              ...item,
              match: false,
              identification: {
                ...item.identification,
                vin: ''
              }
            };
          }
        });
        ClientInfoStore.pushStep(ClientInfoStore.vehicleBasicInfo);
        this.loading = false;
      } else {
        if (this.primaryDriverVehicles.length >= 1) {
          ClientInfoStore.pushStep(ClientInfoStore.vehicleBasicInfo);
          this.loading = false;
        } else {
          ClientInfoStore.pushStep(ClientInfoStore.vehicleDetails);
        }
      }
      return prefillResponse;
    } catch (error) {
      this.errorMessage = error.data;
    }
  };
}

const singleton = new Store();
export default singleton;
