import ClientAddressStore from '../components/clientAddress/ClientAddress.store';

export * from './linkUrl';

// Note: Change the VERSION_NUMBER before merging into staging
export const VERSION_NUMBER = '2.1.3';

export const TIMEZONE_ID = ClientAddressStore.timezoneId;

export const INTERNAL_API = process.env.REACT_APP_BACKEND_URL;
export const COOKIE_KEY = process.env.REACT_APP_COOKIE_KEY;

export const MINIMUM_AGE = 16;
export const MAXIMUM_AGE = 100;

export const SEND_GRID_TOKEN = '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';
export const SEND_GRID_API = 'https://api.sendgrid.com/v3/contactdb/recipients';
export const SEND_GRID_USER_LIST_API = 'https://api.sendgrid.com/v3/contactdb/lists/8381520/recipients/';
