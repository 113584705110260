import { action, observable } from 'mobx';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import httpClient from '../../dataLayer/httpClientWrapper';

class Store {
  @observable loading = false;
  @observable quoteId = '';

  @action
  createQuotes = async (data, token) => {
    try {
      this.loading = true;

      const quotesIdentity = await httpClient({
        method: 'POST',
        url: '/quotes',
        data,
        token
      }).finally(() => {
        this.loading = false;
      });

      this.quoteId = quotesIdentity.data.id;

      mixpanel.register({ quoteId: quotesIdentity.data.id });
      mixpanel.track('Create quote request success');
      TagManager.dataLayer({
        dataLayer: {
          event: 'quoteFlow',
          name: 'Create quote',
          step: 121
        }
      });

      return quotesIdentity;
      // if(Object.entries(quotesIdentity.data).length === 0 &&
      // quotesIdentity.data.constructor === Object) {
      //   this.loading = true;
      // } else {
      //   this.loading = false;
      //   return quotesIdentity;
      // }
    } catch (error) {
      this.errorMesssage = error;
    }
  };

  @action
  subscribeMarketing = (data, token) => {
    return httpClient({
      method: 'POST',
      url: '/marketing/subscribe',
      data,
      token
    });
  };
}

const singleton = new Store();
export default singleton;
