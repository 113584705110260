/**
 * User email store class
 * Located in the quoting flow
 */

import { observable, action } from 'mobx';
import ClientInfoStore from '../../views/clientInfo/ClientInfo.store';
import ClientNameStore from '../clientName/ClientName.store';
import ClientBirthdateStore from '../clientBirthdate/ClientBirthdate.store';
import ClientAddressStore from '../clientAddress/ClientAddress.store';
import { DriverPrefillStore } from '../../stores';
import {
  formatDate
} from '../../utils/dateFormatter';

class Store {
  @observable email = '';
  @observable errorMessage = '';
  @observable driverPrefillData = {};

  handleEmailChange = (evt) => {
    this.email = evt.target.value.replace(/\s/g, '');
  };

  handleNextStep = (token) => {
    this.handleAddClientInfo(token);
    ClientInfoStore.pushStep(ClientInfoStore.vehicleBasicInfo, false, true);
  };

  @action.bound
  handleAddClientInfo = async (token) => {
    const ClientBasicInfo = {};
    ClientBasicInfo.drivers = [
      {
        firstName: ClientNameStore.firstName,
        lastName: ClientNameStore.lastName,
        birthDate: formatDate(ClientBirthdateStore.birthDate)
      }
    ];
    ClientBasicInfo.addresses = [
      {
        addressType: 'Current',
        address1: ClientAddressStore.selectedAddressPlace.address1,
        address2: ClientAddressStore.address2,
        city: ClientAddressStore.selectedAddressPlace.city,
        state: ClientAddressStore.selectedAddressPlace.state,
        zip: ClientAddressStore.selectedAddressPlace.zipCode,
        country: ClientAddressStore.selectedAddressPlace.country
      }
    ];
    const response = await DriverPrefillStore.driverPrefill(
      ClientBasicInfo,
      ClientAddressStore.createQuoteResponseId,
      token
    );
    this.driverPrefillData = response;
  };
}

const singleton = new Store();
export default singleton;
