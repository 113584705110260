/**
 * Username store class
 */

import { observable, runInAction } from 'mobx';
import ClientInfoStore from '../../views/clientInfo/ClientInfo.store';
import {
  toTitleCase, extract
} from '../../utils/dateFormatter';

class Store {
  @observable firstName = '';
  @observable lastName = '';
  @observable errorMessage = '';
  @observable result = {};

  handleFNameChange = (evt) => {
    this.firstName = toTitleCase(extract(evt.target.value, '[a-zA-Z \'-]+'));
  };

  handleLNameChange = (evt) => {
    this.lastName = toTitleCase(extract(evt.target.value, '[a-zA-Z \'-]+'));
  };

  handleNextStep = (AuthStore) => {
    this.handleGetSessionToken(AuthStore);
    ClientInfoStore.pushStep(ClientInfoStore.clientBirthDate);
  };

  handleGetSessionToken = async(authStore) => {
    try {
      const sessionResponse = await authStore.getSession();
      const data = sessionResponse.data;
      runInAction(() => {
        this.result = data;
      });
    } catch (error) {
      runInAction(() => {
        this.errorMessage = error;
      });
    }
  }
}

const singleton = new Store();
export default singleton;
