import { observable, action, computed } from 'mobx';
// import NurseBanner from '../images/L3/Nurse Banner.jpg';
// import PilotBanner from '../images/L3/Pilot Banner.jpg';
// import FreelanceBanner from '../images/L3/Freelancer Banner.jpg';
// import ConsultantBanner from '../images/L3/Consultant Banner.jpg';
// import LawyerBanner from '../images/L3/Lawyer Banner.jpg';
// import SalePersonBanner from '../images/L3/SalesPerson Banner.jpg';
// import TruckerBanner from '../images/L3/Truck Banner.jpg';
// // import BeekeeperBanner from '../images/L3/Beekeeper Banner.jpg';
// import WorkFromHomeBanner from '../images/L3/Work From Home Banner.png';
// import FlightAttendBanner from '../images/L3/Flight Attendent Banner.jpg';
// import MilitaryBanner from '../images/L3/Military Banner.jpg';
// import DoctorBanner from '../images/L3/Doctor Banner.jpg';
// import VacationerBanner from '../images/L3/Vacationer Banner.jpg';
// import ActionBanner from '../images/L3/Action Banner.jpg';
// import MicroMoBanner from '../images/L3/MicroMo Banner.jpg';
// import NurseCircle from '../images/L3/Nurse Circle.jpg';
// import TravelCircle from '../images/pausebilities/waiting_passenger.svg'
// import FreelanceCircle from '../images/L3/Freelancer Circle.jpg';
// // import BeeCircle from '../images/L3/Beekeeper Circle.jpg';
// import WorkFromHomeCircle from '../images/L3/Work From Home Circle.jpg';
// import FlightAttCircle from '../images/L3/Flight Attendent Circle.jpg';
// import LawyerCircle from '../images/L3/Lawyer Circle.jpg';
// import MilitaryCircle from '../images/L3/Military Circle.jpg';
// import SalePersonCircle from '../images/L3/Salesperson Circle.jpg';
// import TruckCircle from '../images/L3/Truck Circle.jpg';
// import VacationCircle from '../images/L3/Vacationer Circle.jpg'
import MicroMoCircle from '../assets/images/limitlessPauseibilitiesBanners/Limitless Pauseibilities - Move - Large.jpg';
import MicImage from '../assets/images/userImages/User_1@3x.png';
import ChrisImage from '../assets/images/userImages/User_3@3x.png';
import NurseHeadShot from '../assets/images/headshots/Zoe1.png';
import PilotHeadShot from '../assets/images/business/Profile_Photo_3@3x.png';
import FreeLanceHeadShot from '../assets/images/headshots/Charles.jpg';
import LawyerHeadShot from '../assets/images/headshots/Jermaine.jpg';
import TruckerHeadShot from '../assets/images/avatar2.png';
// import BeekeeperHeadShot from '../assets/images/avatar3.png';
import WorkFromHomeHeadShot from '../assets/images/avatar3.png';
import FlightHeadShot from '../assets/images/headshots/Julia.jpg';
import DoctorHeadShot from '../assets/images/userImages/User_2@3x.png';
import VacHeadShot from '../assets/images/business/Profile_Photo_1@3x.png';
import ActiveHeadShot from '../assets/images/avatar1.png';
import MicroHeadShot from '../assets/images/headshots/Jake.jpg'

import { ImgMap } from '../components/AppImg';

class Store {
  constructor() {
    this.getSelectAffinity
  }

  @observable clickedAffinityTitle: string = '';
  @observable selectedAffinity: any[] = [];

  @action
  getAffinityTitle = (occpuName: string) => {
    this.clickedAffinityTitle = occpuName
    this.getAffinityContent();
  }

  @computed
  get getSelectAffinity () {
    const urlArr = window.location.pathname.split('/');
    const slugName = urlArr[ urlArr.length - 1];
    return this.getAffinityTitle(slugName);
  }

  @computed
  get hasAffinityContent () {
    return (this.selectedAffinity.length === 0 ? undefined : this.selectedAffinity[ 0 ]);
  }

  getAffinityContent = () => {
    this.selectedAffinity =
    this.affinityItems.filter(affItem => affItem.slug === this.clickedAffinityTitle)
  }

  hasBigHeader = ['nurses', 'truckers', 'military', 'salespeople'];

  @computed
  get hasSmallHeader () {
    return this.hasBigHeader.includes(this.clickedAffinityTitle) ? '' : ' small-header';
  }

  pane2Header= 'saving with Pact'

  commonMobileHead = `Pause your car insurance and save up to
  an additional 38% with Pact.`

  sameMobileHead = `Pause your car insurance when you're on the road to save
  an additional 38% with Pact Pause.`

  flyMobileHead = `Pause your car insurance when you're flying to
  save an additional 38% with Pact Pause.`

  commonJulQuote = `“I saved by pausing 5 times during my policy when I was flying
  for work. Major savings!”`

  commonAlecQuote = `“Car insurance has never been this easy. Pact took the pain
  point of shopping for insurance and solved it. Pausing was so easy that it didn’t
  seem real!”`

  commonMicQuote = `“I’m always on the road for work, so the Pause feature has
  been awesome for me!”`

  affinityItems = [
    { title: 'Nurses',
    subheader: `Take care of your insurance bill while you care for others.
    Nurses can save up to an additional 38% pausing their car insurance with
    Pact Pause.` , slug: 'nurses', bannerImage: ImgMap.L3.Nurse_Banner,
    header: 'Flexible insurance for', whiteHead: 'when you’re away.',
    circleImage: ImgMap.L3.Nurse_Circle,
    subText: `Even if you’re away providing care, your car is still racking up
    bills back home. Schedule a pause in app and get premium savings back.`,
    pane3Header: 'Nurses have already started ',
    quote: `“Cannot stress how much I love Pact! I really prefer app based
    insurance companies. Prices are amazing!!! Love love love”`,
    userName: 'Janet', location: 'Oxford', userImage: NurseHeadShot,
    mobileHeader: `Nurses can save up to an additional
    38% pausing their car insurance with Pact Pause.`},
    { title: 'Pilots',
    subheader: `Savings for the long haul. Pause your car insurance when you're
    flying to save an additional 38% with Pact Pause.`, slug: 'pilots',
    header: 'Your car doesn’t fit', whiteHead: 'on the flight deck.',
    bannerImage: ImgMap.L3.Pilot_Banner,
    circleImage: ImgMap.pauseibilities.waiting_passenger,
    subText: `Why pay for car insurance when you’re away from home base? With
    Pact, get a full policy from your phone in seconds, choose the coverages
    you need, and pause for extra savings.`,
    pane3Header: 'Pilots have already started  ',
    quote: `“I pause my coverage when I fly because I'm gone for at least
    2 days. Have saved big! Love this feature”`, userName: 'Rob',
    location: 'Akron' , userImage: PilotHeadShot,
    mobileHeader: this.flyMobileHead},
    { title: 'Freelancers',
    subheader: `Car insurance as flexible as your work. Pause your car
    insurance and save up to an additional 38% with Pact.`, slug: 'freelancers',
    header: 'The daily commute', whiteHead: 'isn’t how you roll.',
    bannerImage: ImgMap.L3.Freelancer_Banner,
    circleImage: ImgMap.L3.Freelancer_Circle,
    subText: `Choose what you want, when you need it. Feel like roaming?
    Schedule a pause when you’re away from home and get premium savings back.`,
    pane3Header: 'Freelancers have already started  ',
    quote: `“I love freelancing because of the flexibility it gives me.
    Anywhere is my office, and now Pact gives me flexibility to choose when my
    car is covered and when it's not, allowing me to travel”`,
    userName: 'Charlie', location: 'St. Louis', userImage: FreeLanceHeadShot,
    mobileHeader: this.commonMobileHead },
    { title: 'Points Maximizers',
    subheader: `Maximize your points proficiency. Pause your car insurance
    when on-the-go to save an additional 38% with Pact Pause.`,
    slug: 'points_maximizers' , bannerImage: ImgMap.L3.SalesPerson_Banner,
    circleImage: ImgMap.pauseibilities.waiting_passenger, subText: `You pick the best credit card and frequent flier
    program for rewards, why not the best insurer, too? With Pact Pause,
    you get cash back when you instantly pause.`, header: 'Earn cash back while',
    whiteHead: `you're earning points.`,
    pane3Header: 'Points maximizers have already started',
    quote: this.commonJulQuote, userName: 'Julia', location: 'Cincinnati',
    userImage: FlightHeadShot,
    mobileHeader: `Car insurance that rewards users. Pause your car
    insurance with Pact Pause and save.`},
    { title: 'Consultants',
    subheader: `Pack. Park. Pause.  Pause your car insurance when you're on
    the job to save an additional 38% with Pact Pause.`, slug: 'consultants',
    header: 'Boarding a flight is', whiteHead: 'your morning routine.',
    bannerImage: ImgMap.L3.Consultant_Banner, circleImage: ImgMap.pauseibilities.waiting_passenger,
    subText: `But, unfortunately, you’re still paying for car insurance back
    home. If you spend more time in the airport than at home, pause and
    restart your coverage on demand for extra savings.`,
    pane3Header: 'Consultants have already started  ',
    quote: this.commonMicQuote,
    userName: 'Michael', location: 'Real Estate, Columbus', userImage: MicImage,
    mobileHeader: `Pause your car insurance when you're on the job to save
    an additional 38% with Pact Pause.` },
    { title: 'Lawyers',
    subheader: `Pause your car insurance and save up to an additional 38% with
    Pact. No negotation needed.`, slug: 'lawyers',
    header: 'Control how much', whiteHead: 'you save.', bannerImage: ImgMap.L3.Lawyer_Banner,
    circleImage: ImgMap.L3.Lawyer_Circle,
    subText: `Get better rates for what you need. Get money back for what you
    don’t. You shouldn’t need to cut a deal to save on car insurance.`,
    pane3Header: 'Lawyers have already started  ',
    quote: `“Was out of town for a week for work and saved 20% of my premium
    for the month. Thanks Pact!”`,
    userName: 'Jermaine', location: 'Cleveland', userImage: LawyerHeadShot,
    mobileHeader: `Pause your car insurance and save up to an additional
    38% with Pact. No negotation needed.` },
    { title: 'Salespeople',
    subheader: `Work got you on the move? Pause your car insurance when you're
    on the road to save an additional 38% with Pact Pause.`, slug: 'salespeople',
    header: 'You’re out hustling.', whiteHead: 'Why pay for your parked car?',
    bannerImage: ImgMap.L3.SalesPerson_Banner, circleImage: ImgMap.L3.Salesperson_Circle,
    subText: `Why pay for what you don’t need. Give your car insurance a rest
    while you’re pounding the pavement. Schedule a pause for work trips and
    get premium savings back.`,
    pane3Header: 'Salespeople have already started  ',
    quote: `“Love how easy it is to start new insurance. The pricing is
    amazing.”`, userName: 'Christopher', location: 'Sales, Columbus', userImage: ChrisImage,
    mobileHeader: this.sameMobileHead},
    { title: 'Truck Drivers',
    subheader: `Curb your bills while you work. Pause your car insurance when
    you're on the road to save an additional 38% with Pact Pause.`,
    slug: 'truckers', header: 'When you’re on the job,', whiteHead:'your car isn’t.',
    bannerImage: ImgMap.L3.Truck_Banner, circleImage: ImgMap.L3.Truck_Circle,
    subText: `So why pay for car insurance you’re not using? Introducing Pact
    Pause. Get money back for the days your car’s just sitting at the terminal.`,
    pane3Header: 'Truck drivers have already started ',
    quote: `“Pact is awesome! It was easy to get my quote online, and they
    are by far a lower cost than anyone out there. Plus, as a truck driver,
    they offer better coverage and options for the money.”`,
    userName: 'Neil', location: 'Trucker, Akron' , userImage: TruckerHeadShot,
    mobileHeader: this.sameMobileHead},
    // { title: 'Beekeepers',
    // subheader: `Save up to 38% on car insurance and Pause coverage?
    // You better Bee-lieve it.`, slug: 'beekeepers',
    // header: 'Save with the hive', whiteHead: 'or away from home.',
    // bannerImage: BeekeeperBanner , circleImage: BeeCircle,
    // subText: `Pact lets you pause your car insurance and gives you money back.
    // So you can save when you’re prepping for pollination.`,
    // pane3Header: 'Beekeepers have already started  ',
    // quote: `“Tire change in 10 minutes! Ubers post-claim. Pact gets it.
    // Can’t look back”`, userName: 'Carla', location: 'Youngstown',
    // userImage: BeekeeperHeadShot,
    // mobileHeader: `Save up to 38% on car insurance and Pause coverage?
    // You better Bee-lieve it.` },
    { title: 'Working from home',
    subheader: `Park your insurance bill while you’re at home.
    Save an additional 38% while you work from home by using Pact Pause.`, slug: 'work_from_home',
    header: 'When you’re on the job,', whiteHead: 'your car isn’t.',
    bannerImage: ImgMap.L3.Work_From_Home_Banner , circleImage: ImgMap.L3.Work_From_Home_Circle,
    subText: `So why pay for car insurance you’re not using? Introducing Pact Pause.
    Get money back for the days your car’s just sitting in the garage.`,
    pane3Header: 'Customers working from home have already started  ',
    quote: `“If you’re looking for car insurance and you’re not a person who likes to travel,
    the best way to save is with Pact by using their Pause feature!”`, userName: 'Vasel', location: 'Akron',
    userImage: WorkFromHomeHeadShot,
    mobileHeader: `Save up to 38% on car insurance while you work from home with Pact Pause.` },
    { title: 'Flight Attendants',
    subheader: `Living the layover life? Pause your car insurance when you're
    flying to save an additional 38% with Pact Pause.`,
    slug: 'flight_attendants',
    header: 'You’ve got enough delays.', whiteHead: 'Get instant savings.',
    bannerImage: ImgMap.L3.Flight_Attendent_Banner, circleImage: ImgMap.L3.Flight_Attendent_Circle,
    subText: `Pause your car insurance while you’re zigzagging across
    the globe for savings on demand.`,
    pane3Header: 'Flight attendants have already started  ',
    quote: this.commonJulQuote, userName: 'Julia', location: 'Cincinnati',
    userImage: FlightHeadShot , mobileHeader: this.flyMobileHead},
    { title: 'Military Members, Veterans, and Family',
    subheader: `Flexible coverage and service for those who serve. Pause your
    car insurance and save up to an additional 38% with Pact.`, slug: 'military',
    header: 'Deployed for months or', whiteHead: 'training for a weekend?',
    bannerImage: ImgMap.L3.Military_Banner, circleImage: ImgMap.L3.Military_Circle,
    subText: `Pact lets you pause your car insurance and gives you money
    back. So you can save while you serve.` ,
    pane3Header: 'Military members, vets, and family have already started',
    quote: this.commonAlecQuote, userName: 'Alec', location: 'Sylvania',
    userImage: VacHeadShot,
    mobileHeader: this.commonMobileHead},
    { title: 'Doctors',
    subheader: `Flexibility is our speciality. Pause your car insurance
    and save up to an additional 38% with Pact.`, slug: 'doctors',
    header: 'You have too many', whiteHead: 'patients to be patient.',
    bannerImage: ImgMap.L3.Doctor_Banner, circleImage: ImgMap.L3.Nurse_Circle,
    subText: `Choose what you want, when you need it. Away for work? Schedule
    a pause in the app. We’ll give you money back for the days you don’t drive.`,
    pane3Header: 'Doctors have already started  ',
    quote: `“Pact is a perfect fit for my constantly changing schedule.
    Rotating at different hospitals means some weeks I need my car and others
    it doesn't get used at all. The pact and pause feature saves me time and
    money, giving me one less thing to work about.”`, userName: 'Liz',
    location: 'Toledo', userImage: DoctorHeadShot,
    mobileHeader: this.commonMobileHead },
    { title: 'Frequent Flyers',
    subheader: `Built for those on the fly. Pause your car insurance when
    you're flying to save an additional 38% with Pact Pause.`,
    slug: 'frequent_flyers',
    bannerImage: ImgMap.L3.Consultant_Banner, circleImage: ImgMap.pauseibilities.waiting_passenger,
    subText: `While you're collecting frequent flier miles on the road,
    your car is collecting dust and unused premium back home. With Pact
    Pause, we let you pause instantly while on the road, saving you money.`,
    pane3Header: 'Frequent fliers have already started',
    quote: this.commonMicQuote, userName: 'Michael', header: `Because travel savings`,
    whiteHead: `shouldn't end at frequent flier miles.`,
    location: 'Real Estate, Columbus', userImage: MicImage,
    mobileHeader: this.flyMobileHead },
    { title: 'Vacationers',
    subheader: `When you’re on vacaction, so is your car.
    Pause your car insurance with Pact Pause and save.`,
    slug: 'vacationers',
    header: 'Spend money on fun,', whiteHead: ' not car insurance.',
    bannerImage: ImgMap.L3.Vacationer_Banner, circleImage: ImgMap.L3.Vacationer_Circle,
    subText: `Why pay for insurance while you’re away? Pause your coverage
    and get premium savings back.`,
    pane3Header: 'Vacationers have already started  ',
    quote: this.commonAlecQuote, userName: 'Alec', location: 'Sylvania', userImage: VacHeadShot,
    mobileHeader: `When you’re on vacation, so is your car. Pause your car
    insurance with Pact Pause and save.` },
    { title: 'Active Adventurers',
    subheader: `Flexible car insurance for an active lifestyle. Pause your
    car insurance with Pact Pause and save.`, slug: 'active_adventurers',
    header: 'Car insurance', whiteHead: 'as mobile as you.',
    bannerImage: ImgMap.L3.Action_Banner, circleImage: ImgMap.pauseibilities.waiting_passenger,
    subText: `When you’re on the move but not behind the wheel, pause
    your coverage and save some cash.`,
    pane3Header: 'Active adventurers have already started ',
    quote: `“I did Pact Pause because I was on vacation and saved big!
    Thanks Pact.”`, userName: 'Tim', location: 'Cleveland',
    userImage: ActiveHeadShot, mobileHeader: `Flexible car insurance for an
    active lifestyle. Pause your car insurance with Pact Pause and save.` },
    { title: 'Micromobility Movers',
    subheader: `Riding, not driving? Pause your car insurance and save an
    additional 38% with Pact Pause.`, slug: 'micromobility_movers',
    header: 'Buzz through traffic.', whiteHead: 'Give your wallet a boost.',
    bannerImage: ImgMap.L3.MicroMo_Banner, circleImage: MicroMoCircle,
    subText: `Driving isn’t always the best option. Or the most fun.
    Pact Pause offers flexible coverage that saves you money however you want
    to move.`,
    pane3Header: 'Micromobility movers have already started ' ,
    quote: `“My car sits in the garage on weekdays when I scoot into work.
    Now I don't pay for driving coverage during the week!”`,
    userName: 'Jake', location: 'Cincinnati', userImage: MicroHeadShot ,
    mobileHeader: `Riding, not driving? Pause your car insurance and save
    an additional 38% with Pact Pause.`} ];
}

const singleton = new Store();
export default singleton;
