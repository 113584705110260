/* eslint-disable no-undef */
import { action, observable, computed } from 'mobx';
import { multiFilter } from '../utils/utils';
import { WEB_QUOTING_FLOW } from '../constants/linkUrl';
import { NavigationStore } from '.';

class Store {
  @observable resultAddress = {
    address: '',
    stateLongName: '',
    state: ''
  };

  @computed
  get stateName () {
    return  (this.resultAddress.stateLongName.length > 0 ?
    this.resultAddress.stateLongName : undefined
    );
  }

  @action
  getStateFromZip = async (zipCode) => {
    const geocoderService = new google.maps.Geocoder();
    const geocoderRequest = { componentRestrictions:
      { country: 'US', postalCode: zipCode } };
    await geocoderService.geocode(geocoderRequest, (results) => {
      if (results.length === 0) {
        NavigationStore.push(WEB_QUOTING_FLOW);
        return;
      }
      const resultObject = results[ 0 ];
      this.resultAddress.address = resultObject.formatted_address;
      this.resultAddress.stateLongName = multiFilter(resultObject.address_components,
        'administrative_area_level_1')? multiFilter(
          resultObject.address_components,
          'administrative_area_level_1'
        ).long_name
        : '';
      this.resultAddress.state = multiFilter(
        resultObject.address_components,
        'administrative_area_level_1'
      )
          ? multiFilter(
            resultObject.address_components,
            'administrative_area_level_1'
          ).short_name
          : '';
      this.resultAddress.zipCode = multiFilter(
        resultObject.address_components,
        'postal_code'
      )
            ? multiFilter(resultObject.address_components, 'postal_code')
              .long_name
            : '';
      this.redirectUser(this.resultAddress.stateLongName);
    });
  }

  @action
  redirectUser = async (zipaddress) => {
    if (zipaddress !== 'Ohio') {
      NavigationStore.push('/joinPact');
    } else {
      NavigationStore.push(WEB_QUOTING_FLOW);
    }
  }
}

const singleton = new Store();
export default singleton;
