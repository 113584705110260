/**
 * Common image component
 */

import React, { ImgHTMLAttributes, useState } from 'react'
import { isMobile } from '../../utils/utils';
export { default as ImgMap } from './imgMap';

interface AppImgProps extends ImgHTMLAttributes<any> {
  local?: Boolean
}

export const baseImgURL = 'https://pact-marketing-external.s3-us-west-1.amazonaws.com'

export const getImgPath = (src: string, local: any = false, webp: any = false): string => {
  let path = src

  if (webp && !isMobile()) {
    const srcPath = path.split('.')[0]
    const srcFormat = path.split('.')[1]
    srcFormat != 'svg' && (path = `${srcPath}.webp`)
  }

  if (local) {
    return path
  }

  return `${baseImgURL}${path}`
}

export const check_webp_feature = (feature = 'lossy', callback: Function) => {
  let kTestImages: any = {
      lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
      lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
      alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
      animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
  };
  let img = new Image();
  img.onload = function () {
    let result = (img.width > 0) && (img.height > 0);
      callback(feature, result);
  };
  img.onerror = function () {
      callback(feature, false);
  };
  img.src = "data:image/webp;base64," + kTestImages[feature];
}

const AppImg: React.FC<AppImgProps> = (props) => {
  const { src = '', local = false, ...rest } = props

  const srcPath = src.split('.')[0]
  const srcFormat = src.split('.')[1]

  return (
      srcFormat === 'svg' ?
      <img src={getImgPath(src, local)} {...rest} /> :
      <picture>
        <source type="image/webp" srcSet={`${getImgPath(srcPath, local)}.webp`} />
        <img src={getImgPath(src, local)} {...rest} />
      </picture>
  )
}

export default AppImg
