/**
 * Quoting flow integration view store class
 *
 * Functions:
 * - moveTwoSteps: Move Two Steps
 * - moveThreeSteps: Move Three Steps
 * - moveFourSteps: TODO
 * - stepProgression: Step Progression
 * - popLevelBack: Back to previous view
 * - popLevelOwnerBack: TODO
 * - resetData: Reset data
 * - pushStep: Go to the next view
 */

import { observable, action } from 'mobx';

class Store {
  @observable levelStack = [];
  @observable stepNavMethod = 'push';
  @observable stepProgress = 0;
  clientBasicInfo = 'clientBasicInfo';
  clientBirthDate = 'clientBirthDate';
  clientAddress = 'clientAddress';
  clientAddressDetails = 'clientAddressDetails';
  clientEmail = 'clientEmail';
  pactNotInState = 'pactNotInState';
  beIntouch = 'beIntouch';
  vehicleBasicInfo = 'vehicleBasicInfo';
  vehicleDetails = 'vehicleDetails';
  vehicleVIN = 'vehicleVIN';
  vehicleOwnershipStatus = 'vehicleOwnershipStatus';
  driverBasicInfo = 'driverBasicInfo';
  driverDetailsInfo = 'driverDetailsInfo';
  newDriverInfo = 'newDriverInfo';
  newDriverDetailsInfo = 'newDriverDetailsInfo';
  driverLicenseDetails = 'driverLicenseDetails';
  driverPrevInsurance = 'driverPrevInsurance';
  driverTrafficCollision = 'driverTrafficCollision';
  lienHolderInfo = 'lienHolderInfo';
  hasCompletedSteps = 'hasCompletedSteps'
  @observable currentSelectedIndex = this.clientBasicInfo;
  @observable isShowErrorPopup = false
  @observable errorPopupID = ''

  @action.bound
  update(defaultKey, defaultValue) {
    if (typeof defaultKey === 'string') {
      this[ defaultKey ] = defaultValue;
    } else {
      Object.assign(this, defaultKey);
    }
  }

  basicInfoStack = [
    this.clientBasicInfo,
    this.clientBirthDate,
    this.clientAddress,
    this.clientAddressDetails,
    this.clientEmail
  ];
  vehicleInfoStack = [
    this.vehicleBasicInfo,
    this.vehicleDetails,
    this.vehicleVIN,
    this.vehicleOwnershipStatus,
    this.lienHolderInfo
  ];

  driverInfoStack = [
    this.driverBasicInfo,
    this.driverDetailsInfo,
    this.newDriverInfo,
    this.newDriverDetailsInfo,
    this.driverLicenseDetails,
    this.driverPrevInsurance,
    this.driverTrafficCollision
  ];

  moveTwoSteps = () => {
    return ((this.currentSelectedIndex === this.lienHolderInfo
    && !this.levelStack.includes(this.vehicleDetails)) ||
  (this.currentSelectedIndex === this.newDriverDetailsInfo &&
    this.levelStack.includes(this.newDriverInfo)) ||
    (this.currentSelectedIndex === this.vehicleOwnershipStatus &&
      this.levelStack.includes(this.vehicleDetails) &&
      !this.levelStack.includes(this.vehicleVIN)));
  }

  moveThreeSteps = () => {
    return ((this.currentSelectedIndex === this.driverPrevInsurance ||
    (this.currentSelectedIndex === this.lienHolderInfo &&
      this.levelStack.includes(this.vehicleDetails) &&
      !this.levelStack.includes(this.vehicleVIN)) ||
      (this.currentSelectedIndex === this.vehicleOwnershipStatus &&
        this.levelStack.includes(this.vehicleVIN)))); }

  moveFourSteps = () => {
    return ((this.currentSelectedIndex === this.lienHolderInfo &&
      this.levelStack.includes(this.vehicleVIN))); };

  @action
  stepProgression = (stack) =>
    this.basicInfoStack.includes(stack) ?
      this.stepProgress = 0 :
    this.vehicleInfoStack.includes(stack) ?
      this.stepProgress = 1 :
    this.driverInfoStack.includes(stack) ?
      this.stepProgress = 2 :
    this.hasCompletedSteps === stack ?
      this.stepProgress = 3 : this.stepProgress = 2;

  @action.bound
  popLevelBack() {
    this.stepNavMethod = 'pop';
    this.moveTwoSteps() ? (
    this.levelStack.pop(),
    this.levelStack.pop(),
    this.update({
      currentSelectedIndex: this.levelStack[ this.levelStack.length - 1 ]
    })): this.moveThreeSteps() ?
    (this.levelStack.pop(),
    this.levelStack.pop(),
    this.levelStack.pop(),
    this.update({
      currentSelectedIndex: this.levelStack[ this.levelStack.length - 1 ]
    })) :
    this.moveFourSteps() ?
    (this.levelStack.pop(),
    this.levelStack.pop(),
    this.levelStack.pop(),
    this.levelStack.pop(),
    this.update({
      currentSelectedIndex: this.levelStack[ this.levelStack.length - 1 ]
    })) :
  (
    this.levelStack.pop(),
    this.update({
      currentSelectedIndex: this.levelStack[ this.levelStack.length - 1 ]
    }));
  }

  @action.bound
  popLevelOwnerBack() {
    this.stepNavMethod = 'pop';
    this.levelStack.pop();
    this.update({
      currentSelectedIndex: this.levelStack[ this.levelStack.length - 1 ]
    });
  }

  @action.bound
  resetData() {

    this.currentSelectedIndex = this.clientBasicInfo;

    this.stepProgress = 0;
    this.levelStack = [ this.clientBasicInfo ];
  }

  @action.bound
  pushStep(step, replace = false, notMove = false) {
    this.stepNavMethod = 'push';
    const newStack = [ ...this.levelStack, this.clientBasicInfo ];
    if (replace) {
      newStack[ newStack.length - 1 ] = step;
    } else {
      newStack[ newStack.length ] = step;
    }
    this.levelStack = [ ...new Set(newStack) ];

    if (step === this.vehicleBasicInfo) {
      this.stepProgress = 1;
    }
    if (step === this.driverBasicInfo) {
      this.stepProgress = 2;
    }
    this.stepProgression(step);
    !notMove && this.update({
      currentSelectedIndex: step
    });
    window.scrollTo(0, 0);
  }
}

const singleton = new Store();
export default singleton;
