import moment from 'moment';
import { MINIMUM_AGE, MAXIMUM_AGE } from '../constants';

export const age = (dateString) => {
  const currentYear = new Date().getFullYear();
  const birthDate = new Date(dateString).getFullYear();
  return currentYear - birthDate;
};

export const validateAgeRange = (ageString) => {
  const numericAge = age(ageString);
  const confirmValid = moment(ageString, 'MM/DD/YYYY').format();
  return !isNaN(numericAge) && confirmValid !== 'Invalid date' &&
  numericAge >= MINIMUM_AGE && numericAge <= MAXIMUM_AGE ? true : false;
};

export const getAgeArray = (ageNumber) => {
  // const ageArray =
  //   ageNumber >= 40
  //     ? [ ...Array(40 + 1).keys() ].slice(16)
  //     : [ ...Array(ageNumber + 1).keys() ].slice(16);
  const ageArray = [ ...Array(ageNumber + 1).keys() ].slice(16);
  return ageArray;
};

const monthsOfTheYear = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const previousYears = (startYear) => {
  const currentYear = new Date().getFullYear(),
    years = [];
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};
export const previousFiveYears = previousYears(new Date().getFullYear() - 5);

export const monthWithYear = previousFiveYears.map((yr) => {
  return monthsOfTheYear.map((mon) => {
    return `${ mon } / ${ yr }`;
  });
});

export const excludeFutureDateFormat = () => {
  const currentYearArray = moment(moment(new Date()).toArray());
  const allSetYear = [].concat(...monthWithYear).map((monYr) => {
    const allFormatted = moment(monYr, 'MMM/YYYY').format();
    return moment(moment(allFormatted).toArray());
  });
  const allFilterdYr = allSetYear.filter((monYrs) =>
    Math.sign(currentYearArray.diff(monYrs, 'months', true)) === 1);
  return allFilterdYr.map(oneMonYr => oneMonYr.format('MMMM / YYYY'));
};

export const formatDate = (unformattedDate, licenseAge = 0) => {
  const dateTime = new Date(unformattedDate);
  const year = `${ dateTime.getFullYear() + licenseAge }`;
  const month =
    (dateTime.getMonth() + 1).toString().length < 2
      ? `0${ dateTime.getMonth() + 1 }`
      : `${ dateTime.getMonth() + 1 }`;
  const day =
  dateTime.getDate().toString().length < 2
      ? `0${ dateTime.getDate() }`
      : `${ dateTime.getDate() }`;
  return `${ year }-${ month }-${ day }`;
};

export const validateDate = (enterDateValue) => {
  const dateLength = enterDateValue.replace(/\D+/g,'').length;
  if (dateLength === 8) {
    return validateAgeRange(enterDateValue);
  } else {
    return `${ {
      2: moment(enterDateValue, 'MM').format(),
      4: moment(enterDateValue, 'MM/DD').format()
    } [ dateLength ] || '' }`;
  }
};
export const toTitleCase = words => words.split(' ').map( word =>
  word.substring(0,1).toUpperCase()+ word.substring(1)).join(' ');

export const extract = (str, pattern) => (str.match(pattern) || []).pop() || '';
