import { observable, action } from 'mobx';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import Branch from 'branch-sdk';
import ReactPixel from 'react-facebook-pixel';
import httpClient from '../dataLayer/httpClientWrapper';
import ClientAddressStore from '../components/clientAddress/ClientAddress.store';

class Store {

  @observable errorMessage = '';
  @observable loading = true;
  @observable coveragePackage = {};
  @observable recommendedPackage = [];
  @observable driverVechicle = []

  @action
  generateCoveragePack = async (quoteId, token) => {
    try {
      ReactPixel.track('InitiateCheckout');
      Branch.logEvent('INITIATE_PURCHASE');
      const response = await httpClient({
        method: 'POST',
        url: `/quotes/${ quoteId }/generateCoveragePackages`,
        token
      });
      if (
        Object.entries(response.data).length === 0 &&
        response.data.constructor === Object
      ) {
        this.loading = true;
      } else {
        ReactPixel.track('Generate coverage packages request success');
        Branch.track('Generate coverage packages request success');
        mixpanel.track('Generate coverage packages request success', {
          'Quote ID': quoteId,
          'Quote State': ClientAddressStore.selectedAddressPlace.state
        });
        TagManager.dataLayer({
          dataLayer: {
            event: 'quoteFlow',
            name: 'Generate coverage packages',
            step: 161
          }
        });
        this.coveragePackage = response.data;
        this.recommendedPackage = response.data.quoteData.coveragePackages;
        this.driverVechicle = response.data.quoteData.vehicles;
        this.loading = false;
      }
      return response;
    } catch (error) {
      this.errorMessage = error;
    }
  };

}

const singleton = new Store();
export default singleton;
