import { action } from 'mobx';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import Branch from 'branch-sdk';
import ReactPixel from 'react-facebook-pixel';
import httpClient from '../../dataLayer/httpClientWrapper';
import ClientAddressStore from '../../components/clientAddress/ClientAddress.store';

class Store {

  @action
  setDriverModel = (data, quoteId, token) => {
    ReactPixel.track('Set drivers request sent');
    Branch.track('Set drivers request sent');
    mixpanel.track('Set drivers request success', {
      'Quote ID': quoteId,
      'Quote State': ClientAddressStore.selectedAddressPlace.state
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'quoteFlow',
        name: 'Set drivers',
        step: 151
      }
    });
    return httpClient({
      method: 'POST',
      url: `/quotes/${ quoteId }/setDrivers`,
      data,
      token
    });
  }

  @action
  setTerm = (data, quoteId, token) => {
    ReactPixel.track('Set term request sent');
    Branch.track('Set term request sent');
    mixpanel.track('Set term request success');
    return httpClient({
      method: 'POST',
      url: `/quotes/${ quoteId }/setTerm`,
      data,
      token
    });
  }
}

const singleton = new Store();
export default singleton;
